import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';
import { graphql } from 'gatsby';
import Layout from 'layouts/Clean';
import {
  Title,
  Text,
  Scrollable,
  Hero,
  SEO,
  TShapedBlock,
  SplitTextBlock,
  Button,
  SecretsBlock,
} from 'components';
import heroImg from '../images/storia/17-guido--lucchesi.jpg';
import img02 from '../images/azienda/02-lucchesi-azienda.jpg';
import img04 from '../images/azienda/04-emanuele-lucchesi.jpg';
import img05 from '../images/azienda/05-matteo-lucchesi.jpg';
import img06 from '../images/azienda/06-niccolo-lucchesi.jpg';
import img19 from '../images/storia/19-lucchesi-storia.jpg';
import img20 from '../images/storia/20-lucchesi-umberto.jpg';
import img22 from '../images/storia/22-lucchesi-storia.jpg';
import img24 from '../images/storia/24-marco-lucchesi-storia.jpg';
import img26 from '../images/storia/26-lucchesi-storia.jpg';
import img27 from '../images/storia/27-lucchesi-storia.jpg';

const logos = [img02, img02, img02, img02, img02, img02, img02, img02, img02, img02, img02, img02];

const StoriaPage = ({ location }) => {
  const { t } = useTranslation();

  var today = new Date();
  var year = today.getFullYear();

  const PAGE_PROPERTIES = {
    title: (
      <Trans>
        <Title lower>
          Una <font className="text-secondary">storia</font> lunga più di{' '}
          <font className="text-secondary"> un secolo</font>
        </Title>
      </Trans>
    ),
    image: heroImg,
    year: '1897',
    description: (
      <Trans>
        Tutto ha inizio nel 1897. Guido Lucchesi fonda l’azienda di famiglia dopo aver fatto
        esperienza lavorando sui telai a mano.
      </Trans>
    ),
  };

  const teamImages = [img04, img05, img06];

  return (
    <Layout location={location}>
      <SEO
        keywords={['Vivere in tessuto', 'Lucchesi']}
        title={t("Un secolo di storia ed esperienza nel tessile")}
        description={t("Una storia che inizia nell'800 e arriva ai giorni nostri, attraverso molte generazioni della famiglia Lucchesi")}
        location={location}
      />
      <div className="flex flex-col">
        <section className="mt-12 md:mt-40">
          <Hero {...PAGE_PROPERTIES}></Hero>
        </section>
        <section className="my-24 px-12 md:px-24 text-center md:text-left">
          <div className="flex flex-col md:w-2/3">
            <div className="uppercase my-16">
              <Text serif>{t('mostra della moda e del tessuto contemporaneo')}</Text>
            </div>

            <Trans>
              <Title>
                IL NOSTRO ARCHIVIO NON È <font className="text-secondary">STORICO</font> MA{' '}
                <font className="text-secondary">VIVO</font>
              </Title>
            </Trans>
            <div className="my-8">
              <Link to="/museo">
                <Button transparent>{t('Scopri il museo')}</Button>
              </Link>
            </div>
          </div>
        </section>
        <section className="my-24">
          <TShapedBlock
            invert
            title="1914"
            firstDesc={t(
              'Nel 1914 la fabbrica occupa già l’attuale sede di via Carradori e l’adiacente Piazza Macelli.',
            )}
            secondTitle="1938"
            secondDesc={t(
              'Dopo la fine della prima guerra mondiale, in cui viene sospesa la produzione per i figli in guerra, Guido ha la capacità ed il talento di espandere il volume d’affari fino a raggiungere, nel 1938, i 500 dipendenti.',
            )}
            thirdDesc={t(
              'Un devastante incendio nell’estate dello stesso anno non gli impedisce di ricostruire quanto andato distrutto e di lasciare, quattro anni più tardi, durante il secondo conflitto mondiale, l’azienda ai suoi figli',
            )}
            image={img19}></TShapedBlock>
        </section>
        <section>
          <Hero
            year="1949"
            image={img20}
            description={t(
              `Il Lanificio Fratelli Lucchesi affronta i bombardamenti del 1944 per poi venire frazionato tra i figli di Guido nel 1949. Umberto, il più piccolo, è ancora giovanissimo quando inizia a lavorare in Via Carradori ed è proprio a lui che spetta quella parte dell’azienda`,
            )}></Hero>
        </section>
        <section className="my-12">
          <SplitTextBlock
            image={img22}
            firstText={t('LA TESSITURA È UNA SCIENZA:')}
            secondText={t('NON SMETTERETE MAI DI IMPARARE')}
            label={t('MARCO LUCCHESI')}
          />
        </section>
        <section className="my-12">
          <Hero
            year="1962"
            invert
            image={img24}
            description={t(
              `Suo figlio Marco, dopo il diploma all’Istituto T.Buzzi, inizia il suo percorso accanto a nostro nonno nel 1962.`,
            )}></Hero>
        </section>
        <section className="my-12">
          <Hero
            year="2017"
            image={img26}
            description={t(
              `Noi tre figli, Emanuele, Niccolò e Matteo, dopo percorsi di studio diversi tra ragioneria, tessitura, chimica e giurisprudenza, lavoriamo in azienda con nostro padre fino al 2017, anno della sua scomparsa`,
            )}></Hero>
        </section>
        <section className="my-12">
          <div className="h-screen w-screen relative flex flex-row items-center justify-center">
            <div className="flex flex-col items-center justify-center z-20 md:mx-64 uppercase text-center ">
              <div className="my-12">
                <Title color="white">{year}</Title>
              </div>

              <Title size="small" color="white">
                {t(
                  'Ancora oggi, insieme ai nostri preziosi collaboratori, con orgoglio e passione proseguiamo questa meravigliosa tradizione familiare con più di 120 anni di storia, lavoro, innovazioni, impegno e creatività.',
                )}
              </Title>
            </div>
            <div className="absolute w-full h-full">
              <img className="w-full h-full z-10 object-cover" src={img27}></img>
            </div>
          </div>
        </section>

        <section className="my-12 md:px-12 text-left">
          <div className="px-12 flex flex-col">
            <div className="my-12 uppercase">
              <Text serif>{t('Un filo conduttore nel tempo')}</Text>
            </div>
            <Title>
              <Trans>
                Storia e tradizione continuano ad alimentare le{' '}
                <font className="text-secondary">idee</font> che ogni giorno{' '}
                <font className="text-secondary">ispirano</font> la nostra produzione
              </Trans>
            </Title>
          </div>
        </section>
        <section className="flex md:my-40 md:h-screen justify-center items-center">
          <StaticImage
            className="object-cover"
            src="../images/azienda/16-lucchesi-azienda.jpg"></StaticImage>
        </section>
      </div>
    </Layout>
  );
};

export default StoriaPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
